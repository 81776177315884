  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
  *{
    box-sizing: border-box;
    margin:0;
    padding: 0;
font-family: 'Outfit', sans-serif;
  }
  body{
    background: black;
  }
  h1,h2,h4,p,a,span{
    color: #FFD700;
    text-decoration: none;
  }
  .sort-by {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .sort-by button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border: none;
    background-color: #333;
    color: #FFD700;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .sort-by button:focus {
    outline: none;
  }
  
  .sort-by button:hover {
    background-color: #555;
  }
  
  .sort-by button.active {
    background-color: #777;
  }