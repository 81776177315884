.mask2{
    width: 100%;
    height: 60vh;
    position: relative;
}
.intro-img2{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  
}
.mask2::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: #000;
    opacity: 0.8;
}
.hero2{
height: 100%;
width: 100%;
}
.hero2 .content2{
    position: absolute;
transform: translate(-50%, -50%);
top:35%;
left:50%;
text-align: center;
}
.hero2 .content2 h1{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}
.hero2 .content2 p{
font-size: 1.4rem;
font-weight:200 ;
text-transform: uppercase;
}
.content2 .btn{
    margin: 1rem 0.2rem;

}
@media screen and (max-width:640px){
    .hero2 .content2 h1{
        font-size: 3rem;
    }
    .hero2 .content2 p{
    font-size: 1.4rem;
    }
}