.header{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:1rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}
.header-bg{
    background-color: rgba(0,0,0,0.85);
    transition: 0.5s;
}
.nav-menu{
    display:flex;
}
.nav-menu li{
    padding:0 1rem;
}
.nav-menu li a{
font-size: 1.2rem;
font-weight: 500;
}
ul{
    list-style-type: none;
}
.btn{
    padding:12px 32px;
    font-size: 1rem;
    text-transform:uppercase;
    background: rgb(248,217,15);
    color: #222;
    border:1px solid #fff;
    cursor:pointer;
}
.btn-light{
    background: transparent;
    color:#fff;
    padding:12px 32px;
    font-size: 1rem;
    text-transform:uppercase;
    border:1px solid #fff;
    cursor:pointer;
}
.btn:hover{
    background: rgba(255,255,255,0.2);
    color: #fff;
    transition:0.3s

}
.hamburger{
display: none;
}
@media screen and (max-width:1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,0.9);
        position: absolute;
        top:0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }
    
    .nav-menu.active{
        left:0;
    }
    .nav-menu li{
        padding: 1rem 0;
    }
    .nav-menu li a{
        font-size: 2rem;
    }
    .hamburger{
        display:initial ;
    }
}